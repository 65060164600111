.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e2e2e2;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  letter-spacing: -0.2px;
}

.cc-checkout-container {
  position: relative;
  overflow: hidden;
  width: 55vw;
  height: 100%;
  max-height: 85vh;
  min-height: 75vh;
  padding-top: 1rem;
  border-radius: 5px;
  background-color: #fbfcfe;
}

.cc-checkout-container.ccco-summary-container {
  overflow: hidden;
  width: 55vw;
  height: 100%;
  max-height: none;
  min-height: 85vh;
}

.cc-checkout-upper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  opacity: 1;
}

.cc-checkout-upper.checkout-sum-transform {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.cc-checkout-upper.checkout-sum-transform.ccoo-o50 {
  opacity: 0.3;
}

.cc-checkout-upper.cc-co-guest-upper {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.cc-checkout-left {
  width: 100%;
  height: 100%;
  padding: 0px;
  border-right: 1px none #d6d6d6;
  background-color: #fff;
}

.cc-co-title-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cc-co-title-wrap.cc-co-title-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cc-co-title-wrap.ccco-title-success {
  padding-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
}

.cc-co-title {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  letter-spacing: -0.2px;
}

.cc-co-securebadge {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cc-co-secure-txt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  color: #9d9d9d;
  font-size: 0.8rem;
  font-weight: 600;
}

.cc-co-secure-ico {
  margin-right: 5px;
  font-family: 'Fa solid 900', sans-serif;
}

.cc-co-paym-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 73%;
  margin-top: 0px;
  margin-left: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.cc-co-paym-container.ccco-paycontainer-transform {
  opacity: 1;
  -webkit-transform: translate(0px, 0%);
  -ms-transform: translate(0px, 0%);
  transform: translate(0px, 0%);
}

.cc-co-paym-container.ccco-paycontainer-transform.ccco-guest-container {
  opacity: 0.51;
  -webkit-transform: translate(0px, 0%);
  -ms-transform: translate(0px, 0%);
  transform: translate(0px, 0%);
  cursor: not-allowed;
}

.cc-co-paym-container.ccco-summary-container {
  overflow: auto;
  height: auto;
}

.cc-co-paym-tabcontainer {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #fbfcfe;
}

.cc-co-paym-tab {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 4px solid transparent;
  background-color: #fbfcfe;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  text-decoration: none;
}

.cc-co-paym-tab:hover {
  border-left-color: #3f54ff;
  background-color: #f5f5f5;
  color: #fff;
}

.cc-co-paym-tab:focus {
  border-left-color: #3f54ff;
}

.cc-co-paym-tab.cc-co-paym-tab--active {
  border-left: 4px solid #3f54ff;
  background-color: #fff;
}

.cc-co-tab-txt {
  color: #333;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.cc-co-paym-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 65%;
  height: 100%;
  padding: 20px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cc-co-paym-right.ccco-saved-right {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cc-co-paym-fieldwrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cc-form-field {
  height: 40px;
  margin-bottom: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #dbdfea;
  border-radius: 3px;
  background-color: #fff;
  font-family: 'Open Sans', sans-serif;
  color: #737373;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 400;
}

.cc-form-field::-webkit-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.cc-form-field:-ms-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.cc-form-field::-ms-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.cc-form-field::placeholder {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.cc-form-field.ccco-field-textarea {
  height: 80px;
}

.cc-forminput-50 {
  width: 50%;
}

.cc-forminput-50.ccco-forminput-48 {
  width: 48%;
}

.affiliate-setup-form-inputwrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.affiliate-setup-forminner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cc-form-label {
  font-family: 'Open Sans', sans-serif;
  color: #314557;
  font-size: 0.7rem;
  font-weight: 600;
}

.cc-inputfield-wrap {
  position: relative;
  overflow: hidden;
}

.cc-field-wrap {
  width: 100%;
  margin-bottom: 20px;
}

.cc-field-wrap.mb0 {
  margin-bottom: 0px;
}

.cc-primary--cta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: #3f54ff;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8rem;
  font-weight: 600;
}

.cc-primary--cta:hover {
  opacity: 0.8;
}

.cc-primary--cta.ccco-mt20 {
  margin-top: 30px;
}

.cc-primary--cta.ccco-mt20.ccoo-cta-retry {
  width: 50%;
  background-color: #ea5743;
}

.cc-primary--cta.ccco-mt20.ccco-pays-invoicebtn {
  width: 40%;
  margin-top: 60px;
  margin-left: auto;
}

.cc-primary--cta.ccco-mt20.ccco-mc-nextcta {
  width: 40%;
  margin-top: 60px;
  margin-left: auto;
}

.cc-primary--cta.cco-cta-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.cc-co-form---credit {
  width: 100%;
}

.cc-co-forminner {
  width: 100%;
}

.cc-co-field-wrap {
  width: 100%;
  margin-bottom: 20px;
}

.cc-co-field-wrap.mb0 {
  margin-bottom: 0px;
}

.cc-co-form-field {
  position: relative;
  overflow: hidden;
  height: 40px;
  margin-bottom: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #dbdfea;
  border-radius: 3px;
  background-color: #fff;
  font-family: 'Open Sans', sans-serif;
  color: #737373;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 400;
}

.cc-co-form-field::-webkit-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.cc-co-form-field:-ms-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.cc-co-form-field::-ms-input-placeholder {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.cc-co-form-field::placeholder {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.cc-co-form-label {
  font-family: 'Open Sans', sans-serif;
  color: #314557;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.cc-co-form-label.ccco-saved-title {
  margin-bottom: 0px;
  font-size: 0.75rem;
}

.cc-co-form-label.ccco-saved-sep {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cc-co-form-inputwrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.affiliate-setup-form-inputwrap-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cc-co-fullinput-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tooltip-container {
  position: relative;
}

.tooltip-container.tt-tr {
  position: absolute;
  top: 50%;
  right: 20px;
  z-index: 10;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.tooltip-container.tt-tr {
  position: absolute;
  top: 50%;
  right: 5px;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.tooltip-circle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: help;
}

.tooltip-icon {
  position: relative;
  font-family: 'La solid 900', sans-serif;
  color: #bcbcbc;
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: 400;
}

.tooltip-message {
  position: absolute;
  left: 16px;
  bottom: 100%;
  z-index: 200;
  display: none;
  min-width: 200px;
  padding: 6px;
  border-radius: 6px;
  background-color: #1c1c1c;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.tooltip-message-txt {
  font-family: 'Open Sans', sans-serif;
  color: #dbdfea;
  font-size: 0.7rem;
  font-weight: 400;
  text-align: center;
}

.paym-card-logowrap {
  position: absolute;
  top: 50%;
  right: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate3d(0px, -50%, 3px);
  transform: translate3d(0px, -50%, 3px);
  -webkit-transition: -webkit-transform 500ms cubic-bezier(.77, 0, .175, 1);
  transition: -webkit-transform 500ms cubic-bezier(.77, 0, .175, 1);
  transition: transform 500ms cubic-bezier(.77, 0, .175, 1);
  transition: transform 500ms cubic-bezier(.77, 0, .175, 1), -webkit-transform 500ms cubic-bezier(.77, 0, .175, 1);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.paym-card-ico {
  position: relative;
  margin-top: 70%;
  background-color: transparent;
  font-family: 'Fa solid 900', sans-serif;
  color: #cdcdcd;
  font-size: 1.2rem;
}

.paym-card-ico.paym-card-visa {
  color: #c1b535;
}

.paym-card-ico.paym-card-master {
  color: #3f54ff;
}

.paym-card-ico.paym-card-default {
  margin-top: 0%;
}

.cc-co-toptitlewrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 15%;
  padding: 20px;
}

.cc-co-bottom-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 12%;
  padding: 20px;
}

.cc-co-total-badge {
  position: absolute;
  top: 50%;
  right: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #3f54ff;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.cc-co-total-amount {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 140px;
  padding-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 2rem;
  line-height: 2.6rem;
  font-weight: 700;
}

.cc-co-total-amount.ccco-color-black {
  width: auto;
  color: #323232;
  font-size: 1.4rem;
  line-height: 2rem;
}

.cc-co-total-desc {
  color: rgba(255, 255, 255, 0.73);
  font-size: 0.8rem;
  font-weight: 400;
}

.cc-forminput-25 {
  width: 22%;
}

.cc-forminput-50-copy {
  width: 25%;
}

.tooltip-message-2 {
  position: absolute;
  left: 16px;
  bottom: 100%;
  z-index: 10;
  display: none;
  min-width: 200px;
  padding: 6px;
  border-radius: 6px;
  background-color: #1c1c1c;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.cc-co-credit-cta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 20px 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px 1px 1px 3px;
  border-color: #e0e0e0 #e0e0e0 #e0e0e0 #3f54ff;
  border-radius: 5px;
  background-color: #fafafa;
}

.cc-co-credit-txt {
  font-size: 0.8rem;
  line-height: 1.4rem;
  font-weight: 400;
}

.cc-co-credit-title {
  position: relative;
  margin-bottom: 0px;
  color: #333;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.title-wtooltip {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cc-vsmall-text {
  position: relative;
  width: auto;
  margin-bottom: 0px;
  color: #333;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 500;
  letter-spacing: 0px;
}

.tooltip-container-2 {
  position: relative;
  margin-left: 10px;
}

.tooltip-icon-2 {
  position: relative;
  font-family: 'La solid 900', sans-serif;
  color: #8094ae;
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: 400;
}

.cc-co-titletooltip {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #262626;
}

.body-2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.cc-co-summary-right {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 65%;
  height: 100%;
  padding: 20px 30px 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cc-co-summary-pwrap {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.cc-co-summary-pwrap.ccco-add-ani {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.cc-co-summary-pwrap.cc-co-bundle-summary {
  margin-bottom: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cc-co-sp-titlewrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cc-co-sp-title {
  color: #333;
  font-size: 0.8rem;
  letter-spacing: -0.2px;
  text-transform: none;
}

.cc-co-sp-title.cc-co-sp-title-small {
  font-size: 0.8rem;
}

.cc-co-sp-title.cc-co-sp-title-small.cc-co-mb10 {
  margin-bottom: 10px;
}

.cc-co-sp-desc {
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: -0.2px;
}

.cc-co-sp-desc.ccco-saved-exp {
  font-size: 0.7rem;
  line-height: 1.2rem;
}

.cc-co-sp-desc.ccco-saved-exp.ccco-footer-pbc-title {
  font-style: italic;
}

.cc-co-sp-pricewrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.8rem;
}

.ccco-sp-price {
  font-size: 0.8rem;
  font-weight: 700;
}

.ccco-sp-price.ccco-sp-price-small {
  font-size: 0.8rem;
}

.ccco-sp-price.ccco-sp-price-small-cross {
  color: #de2121;
  font-size: 0.7rem;
  font-weight: 400;
  text-decoration: line-through;
}

.ccco-sp-price.ccco-sp-price-small-discount {
  margin-left: 5px;
  color: #757575;
  font-size: 0.7rem;
  font-weight: 600;
  text-decoration: none;
}

.ccco-sp-price.ccco-sp-price-md {
  font-size: 1.2rem;
  line-height: 1.3rem;
}

.ccco-sugesstionbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 40px;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.ccco-sucards-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-top: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  white-space: nowrap;
}

.ccco-su-pcard {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 46%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 5px;
}

.ccco-su-pcard--img {
  width: 100%;
  height: 90px;
  background-color: #f2f2f2;
}

.ccco-su-pcard--desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 12px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ccco-su-pcard--desc.ccco-fh {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ccco-su-pcard--desc.ccco-fh.ccco-scredit-wrap {
  padding-right: 15px;
  padding-left: 15px;
}

.ccco-su-pcard--desc.ccco-fh.ccco-scredit-wrap.ccco-credit-user-info {
  margin-top: 20px;
  padding: 10px 0px 0px;
  border-top: 1px solid #e5e5e5;
}

.cc-co-outline-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 10px;
  padding: 5px 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #3f54ff;
  border-radius: 3px;
  background-color: transparent;
  font-family: 'Open Sans', sans-serif;
  color: #3f54ff;
  font-size: 0.7rem;
  font-weight: 600;
}

.ccco-su-pcard-desc--left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ccco-su-pcard-desc--left.ccco-fv {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ccco-su-pcard-desc--left.ccco-fv.ccco-w-auto {
  width: auto;
}

.ccco-su-pcard-desc--left.ccco-c-s-applied {
  padding-top: 10px;
}

.ccco-su-pcard--add {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: #3f54ff;
  color: #fff;
  text-decoration: none;
}

.ccco-su-pcard-add--ico {
  font-family: 'La solid 900', sans-serif;
  color: #fff;
}

.ccco-summary-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35%;
  height: 100%;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-left: 1px solid #e5e5e5;
  background-color: #fff;
}

.ccco-s-c-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ccco-sc-formwrap {
  width: 100%;
  margin-top: 20px;
}

.ccco-sum-form {
  margin-bottom: 0px;
}

.ccco-sum-capply--cta {
  position: absolute;
  top: 50%;
  right: 5px;
  padding: 3px 5px 4px 6px;
  border-style: solid;
  border-width: 1px;
  border-color: #3f54ff;
  border-radius: 3px;
  background-color: transparent;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #3f54ff;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: -0.2px;
}

.ccco-sum-capply--cta:hover {
  background-color: #3f54ff;
  color: #fff;
}

.ccco-sum-pdetail-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cc-pright-bd--inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 48%;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cc-pright-bd--inner.ccco-sum-total {
  padding-top: 10px;
  border-top: 1px solid #ddd;
}

.cc-pright-bd--inner.ccco-sum-total.ccco-sum-total-full {
  width: 100%;
}

.cc-pright-bd--inner.ccco-w100 {
  width: 100%;
}

.cc-title-gsm-2 {
  margin-right: 10px;
  margin-bottom: 0px;
  font-family: 'Open Sans', sans-serif;
  color: #8094ae;
  font-size: 0.8rem;
  line-height: 1.1rem;
  font-weight: 400;
  letter-spacing: -0.3px;
}

.cc-title-gsm-2.cc-gsm-pb {
  width: 50%;
}

.cc-title-gsm-2.cc-gsm-pb.ccco-sum-ordertotal {
  color: #333;
  font-weight: 600;
}

.cc-title-gsm-2.cc-gsm-pb-price {
  color: #314557;
  font-weight: 600;
}

.cc-title-gsm-2.cc-gsm-pb-price.ccco-txtbold {
  font-size: 1rem;
  font-weight: 700;
}

.cc-title-gsm-2.ccco-qty-number {
  margin-right: 0px;
  padding-right: 5px;
  padding-left: 5px;
  color: #3f54ff;
  font-weight: 600;
}

.slider-arrow {
  position: absolute;
  top: 40%;
  right: -10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 #c8c8c8;
  -webkit-transform: translate(0%, -40%);
  -ms-transform: translate(0%, -40%);
  transform: translate(0%, -40%);
  color: #29347a;
  text-decoration: none;
}

.arrow-slider-ico {
  font-family: 'La solid 900', sans-serif;
  color: #29347a;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
}

.ccco-sum-cartwrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 100%;
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ccc-sum-viewmore {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  text-decoration: none;
}

.ccco-sum-viewmorelink {
  position: relative;
  bottom: auto;
  z-index: 0;
  margin: 10px auto 0px;
  color: #ababab;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: -0.2px;
  text-decoration: underline;
}

.ccco-su-pcard-pricewrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.ccco-sp-title-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ccco-so-offer-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.ccco-sp-offer-txt {
  padding: 2px 5px;
  border-style: solid;
  border-width: 0.5px;
  border-color: #3f54ff;
  border-radius: 2px;
  background-color: #3f54ff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: -0.2px;
}

.ccco-sp-offer-txt.ccco-offer-cta {
  border-style: dashed;
  border-width: 1px;
  border-color: #e27a18;
  background-color: transparent;
  color: #e27a18;
  text-align: left;
}

.ccco-p-detail-container {
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 65%;
  height: 100%;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 1px none #000;
  background-color: #3f54ff;
  -webkit-transform: translate(100%, 0px);
  -ms-transform: translate(100%, 0px);
  transform: translate(100%, 0px);
  -webkit-transition: -webkit-transform 200ms ease-out;
  transition: -webkit-transform 200ms ease-out;
  transition: transform 200ms ease-out;
  transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
}

.ccco-p-detail-container.ccco-p-detail-container-open {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: translate(0%, 0px);
  -ms-transform: translate(0%, 0px);
  transform: translate(0%, 0px);
}

.ccco-p-details-ibber {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #f5f5f5;
}

.ccco-sp-detail-close {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.ccco-sp-detail-close.ccco-close.cc-btn-trigger {
  z-index: 2;
}

.ccco-sp-detail-close.ccco-close.cc-btn-trigger.cc-co-bundle-listliink.cc-budleclose {
  font-style: normal;
}

.cc-sp-close-ico {
  font-family: 'La solid 900', sans-serif;
  color: #3f54ff;
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 400;
}

.cc-sp-close-ico.ccco-sp-vd-link.ccco-close-ico {
  position: relative;
  margin-top: 0px;
  padding-bottom: 0px;
  border-bottom-style: none;
  color: #333;
  font-size: 0.9rem;
  font-weight: 400;
}

.cc-sp-close-ico.ccco-sp-vd-link.ccco-close-ico.cc-btn-trigger {
  font-size: 1.1rem;
}

.cc-sp-close-ico.cc-guestform-toggle.ccco-close {
  color: #080808;
}

.ccoo-sp-details-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  height: 70%;
  max-height: 70%;
  margin-top: 40px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ccco-sp-detail-desc {
  margin-bottom: 5px;
  font-size: 0.8rem;
  line-height: 1.6rem;
  font-weight: 400;
}

.astats-upgrade {
  position: absolute;
  left: 0px;
  bottom: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  height: auto;
  padding: 5px 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #dbdfea;
  background-image: -webkit-gradient(linear, left top, right top, from(#dbdfea), to(#fff));
  background-image: linear-gradient(90deg, #dbdfea, #fff);
}

.astats-upgrade._w-100 {
  position: relative;
  width: 100%;
}

.stats-info-ico {
  margin-right: 10px;
  font-family: 'Fa solid 900', sans-serif;
  color: #8094ae;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 400;
}

.cc-desc {
  position: relative;
  margin-top: 5px;
  font-family: 'Open Sans', sans-serif;
  color: #8094ae;
  font-size: 0.8rem;
  line-height: 1.4rem;
  font-weight: 400;
}

.astats-upgrade-link {
  color: #314557;
}

.ccco-sum-offerwrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 3px solid #ffc23f;
  background-color: #fafafa;
}

.ccco-sum-offer-pill {
  padding: 2px 4px;
  border-radius: 2px;
  background-color: #ffeddc;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.ccco-sum-offer-pill.ccco-w-auto {
  display: inline;
  width: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ccco-sum-offer-pill.ccco-w-auto.ccco-pill-green {
  border-style: solid;
  border-width: 1px;
  border-color: #25bb40;
  background-color: #deffdb;
}

.ccco-sum-offer-desc {
  margin-left: 10px;
  font-size: 0.7rem;
  font-weight: 400;
  text-decoration: none;
}

.ccco-sum-offer-desc.ccco-s-c-desc {
  margin-top: 10px;
  margin-left: 0px;
}

.ccco-sum-offer-desc.ccco-margin-0 {
  margin-left: 0px;
  font-style: italic;
}

.ccco-sumoffer-innerwrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ccco-guest-form-container-side {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ccco-form-guest {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 0px;
}

.ccco-form-guestinner {
  width: 100%;
}

.cc-co-sum-qty {
  display: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ccco-qty-minus {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #646464;
  text-decoration: none;
}

.ccco-qty-ico {
  font-family: 'La solid 900', sans-serif;
  color: #373737;
  font-size: 1rem;
  font-weight: 400;
}

.ccco-saved-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
}

.ccco-saved-wrap.ccco-mb0 {
  border-bottom-style: none;
}

.ccco-saved-visa-ico {
  font-family: 'La brands 400', sans-serif;
  font-size: 3rem;
  line-height: 2rem;
  font-weight: 400;
}

.image {
  width: 81px;
}

.ccco-saved-detailwrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cco-saved-dwrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-2 {
  width: 60px;
}

.ccco-saved-visalogo {
  width: 40px;
}

.ccco-cards-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ccco-footer-logo {
  width: 35px;
  margin-right: 30px;
}

.ccco-footer-logo.ccco-paypal-ico {
  width: 55px;
}

.ccco-footer-logo.ccco-footer-256 {
  width: 80px;
  margin-right: 0px;
  margin-left: 0px;
}

.ccco-credit-checkbox {
  width: 18px;
  height: 18px;
  margin-top: 0px;
  margin-right: 10px;
  border-width: 1.5px;
  border-color: #3f54ff;
}

.ccco-credit-cb-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cc-co-creditbox {
  width: 90%;
  height: auto;
  margin: 20px auto;
  border-style: solid;
  border-width: 1px;
  border-color: #96a2ff;
  border-radius: 5px;
  background-color: #fff;
}

.text-block {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.7rem;
  font-weight: 600;
}

.ccco-credit-btn {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.7rem;
  line-height: 1.2rem;
  font-weight: 600;
}

.ccco-process-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 88%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ccco-process-inner.ccco-process-inner-full {
  height: 100%;
}

.ccco-process-inner.process-succes-details {
  padding: 40px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ccco-progress-txt {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: -0.2px;
}

.ccco-process-title {
  margin-top: 20px;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: 300;
  text-align: center;
}

.ccco-process-title.ccco-process-stitle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  color: #3ea33b;
  font-size: 1.4rem;
}

.ccco-process-inner-contianer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 70%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.progress-ring-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  min-height: 200px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ccco-process-rlink {
  position: relative;
  max-width: 60%;
  margin-top: 40px;
  font-size: 0.7rem;
  text-align: center;
  letter-spacing: -0.2px;
}

.ccco-process-rlink.ccco-succes-tofggle {
  position: absolute;
  bottom: 80px;
}

.ccco-process-rlink.ccco-mt-10 {
  margin-top: 20px;
}

.ccco-redirectlink {
  color: #939393;
  font-weight: 400;
  text-decoration: underline;
}

.ccco-success-toggle {
  color: #3f54ff;
  text-decoration: none;
}

.ccco-sucess-title {
  margin-top: 0px;
  color: #2dc622;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
  text-align: center;
  letter-spacing: -0.5px;
}

.ccco-progress-pendingico {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 130px;
  height: 130px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ea5743;
  color: #fff;
}

.ccco-pp-ico {
  font-family: 'La solid 900', sans-serif;
  color: #fff;
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 400;
}

.ccco-sum-pro-img {
  width: 60px;
  margin-right: 5px;
  border-radius: 50%;
}

.ccco-paysuccess-ico {
  position: relative;
  margin-right: 10px;
  font-family: 'La solid 900', sans-serif;
  color: #32c137;
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 400;
}

.ccco-pays-titlewrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ccco-title-fv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ccco-pays-orderidwrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.ccco-pays-details-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ccco-pays-details-container.ccco-paysucces-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ccco-pays-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 45%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ccco-pays-details-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ccco-pays-invoice-ico {
  margin-right: 10px;
  font-family: 'La solid 900', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
}

.ccco-guest-form-cside {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 60px;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ccco-p-details-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
}

.ccco-field-100 {
  width: 100%;
}

.ccco-su-pcard-price-fv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.ccco-su-pcard-price-fv.ccco-fla {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ccco-su-pcard-price-fv.ccco-fh {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.ccco-su-pcard--add-sp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 10px;
  padding: 4px 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: #3f54ff;
  color: #fff;
  text-decoration: none;
}

.ccco-sp-vd-link {
  margin-top: 5px;
  padding-bottom: 2px;
  border-bottom: 1px dashed #3f54ff;
  color: #3f54ff;
  font-size: 0.7rem;
  font-weight: 600;
  text-decoration: none;
}

.ccco-sp-vd-link.ccco-s-c-remove {
  position: absolute;
  top: 8px;
  right: 10px;
  padding-bottom: 0px;
  border-bottom-color: #ff3f3f;
  color: #ff3f3f;
  font-size: 0.65rem;
  font-weight: 400;
}

.ccco-profile-card-edit--ico {
  position: relative;
  font-family: 'La solid 900', sans-serif;
  color: #323232;
  font-size: 1.4rem;
  line-height: 1.3rem;
  font-weight: 400;
}

.ccco-profile-edit--btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 2px;
  background-color: transparent;
  color: #fff;
  text-decoration: none;
}

.ccco-profile-edit--btn.cc-guestform-toggle {
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;
}

.ccco-trash-link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.ccco-trash-ico {
  font-family: 'La solid 900', sans-serif;
  color: #ea5743;
  font-size: 1.2rem;
  font-weight: 400;
}

.ccco-product-sliderwrap {
  position: relative;
  width: 100%;
  margin-top: 20px;
}

.ccco-arrow-next {
  position: absolute;
  top: 50%;
  right: -10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #3f54ff;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  font-family: 'La solid 900', sans-serif;
  color: #fff;
  font-size: 0.9rem;
  line-height: 0.9rem;
  font-weight: 400;
  text-decoration: none;
}

.ccco-arrow-prev {
  position: absolute;
  left: -10px;
  top: 50%;
  right: auto;
  display: none;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #3f54ff;
  -webkit-transform: rotateX(0deg) rotateY(-180deg) rotateZ(0deg) translate(0px, -50%);
  transform: rotateX(0deg) rotateY(-180deg) rotateZ(0deg) translate(0px, -50%);
  font-family: 'La solid 900', sans-serif;
  color: #fff;
  font-size: 0.9rem;
  line-height: 0.9rem;
  font-weight: 400;
  text-decoration: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.ccco-s-c-applied {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 20px;
  padding: 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: dashed;
  border-width: 1px;
  border-color: #d2d2d2;
  border-radius: 3px;
  background-color: #f7f7f7;
}

.ccco-p-user-container {
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 65%;
  height: 100%;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 1px none #000;
  background-color: #3f54ff;
  -webkit-transform: translate(100%, 0px);
  -ms-transform: translate(100%, 0px);
  transform: translate(100%, 0px);
  -webkit-transition: -webkit-transform 200ms ease-out;
  transition: -webkit-transform 200ms ease-out;
  transition: transform 200ms ease-out;
  transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
}

.ccco-p-user-container.ccco-p-detail-container-open {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: translate(0%, 0px);
  -ms-transform: translate(0%, 0px);
  transform: translate(0%, 0px);
}

.ccco-footer-powerby {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ccco-footer-pbc {
  color: #333;
  font-size: 0.8rem;
  text-transform: none;
}

.cc-co-sp-bundle-desc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cc-co-budles-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cc-co-bundle-listliink {
  position: relative;
  margin-right: 10px;
  margin-left: 0px;
  padding-bottom: 2px;
  border-bottom: 1px dashed #333;
  color: #333;
  font-size: 0.75rem;
  line-height: 1.2rem;
  font-style: italic;
  font-weight: 600;
  text-decoration: none;
}

.ccco-p-bundle-container {
  position: absolute;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 55%;
  height: 100%;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 1px none #000;
  background-color: #3f54ff;
  -webkit-transform: translate(100%, 0px);
  -ms-transform: translate(100%, 0px);
  transform: translate(100%, 0px);
  -webkit-transition: -webkit-transform 200ms ease-out;
  transition: -webkit-transform 200ms ease-out;
  transition: transform 200ms ease-out;
  transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
}

.ccco-p-bundle-container.ccco-p-detail-container-open {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: translate(0%, 0px);
  -ms-transform: translate(0%, 0px);
  transform: translate(0%, 0px);
}

.ccco-p-bundle-container.ccco-p-detail-container-open {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: translate(0%, 0px);
  -ms-transform: translate(0%, 0px);
  transform: translate(0%, 0px);
}

.ccco-p-bundle-container.ccco-p-detail-container-open-auto {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 55%;
  -webkit-transform: translate(0%, 0px);
  -ms-transform: translate(0%, 0px);
  transform: translate(0%, 0px);
}

.ccco-p-bundles-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ccco-usecredit-form {
  margin-bottom: 0px;
}

.ccco-usecredit-checkbox {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1.2px;
  border-color: #3f54ff;
  border-radius: 3px;
  background-color: transparent;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
}

.ccco-usecredit-checkbox:active {
  background-color: #eff1ff;
}

.ccco-usecredit-check {
  width: 16px;
  height: 16px;
  margin-top: 0px;
  margin-right: 5px;
  margin-left: -15px;
  padding-right: 5px;
  padding-left: 5px;
  border-width: 1.2px;
  border-color: #3f54ff;
  background-color: transparent;
}

.ccco-usecredit-check.w--redirected-checked {
  background-color: #3f54ff;
}

.ccco-usecredit-label.ccco-credit-btn {
  color: #3f54ff;
  font-size: 0.75rem;
  line-height: 1.2rem;
}

.mb-nav-icon {
  margin-bottom: 5px;
  font-family: 'La solid 900', sans-serif;
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.mb-nav-desc {
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 400;
}

.cfs-mobile-bottom-fixed {
  position: fixed;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.cfs-mobile-bottom-fixed.mobile-fixednavigation {
  display: none;
  padding: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.mn-nav-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.cfs-mob-cd-close {
  position: relative;
  font-family: 'La solid 900', sans-serif;
  color: #166088;
  font-size: 1.1rem;
  text-decoration: none;
}

.cfs-form-field-desc {
  position: relative;
  color: #166088;
  font-size: 0.8rem;
  font-style: italic;
}

.cfs-form-label {
  color: #fff;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-weight: 600;
  letter-spacing: -0.2px;
}

.cfs-form-label.color-primary {
  width: 100%;
  color: #166088;
}

.cfs-btn-secondary.cfs-btn-outline {
  margin-top: 0rem;
  padding: 12px 18px;
  border-style: solid;
  border-width: 1.2px;
  border-color: #dbdbdb;
  border-radius: 5px;
  background-color: transparent;
  box-shadow: none;
  -webkit-perspective: 66px;
  perspective: 66px;
  -webkit-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
  -webkit-transform: perspective(0px);
  transform: perspective(0px);
  -webkit-transition: background-color 150ms cubic-bezier(.55, .055, .675, .19);
  transition: background-color 150ms cubic-bezier(.55, .055, .675, .19);
  color: #454545;
  font-size: 0.9rem;
  line-height: 0.9rem;
  font-weight: 600;
  letter-spacing: -0.2px;
}

.cfs-btn-secondary.cfs-btn-outline:hover {
  border-color: #d8d8d8;
  border-radius: 5px;
  background-color: #fafafa;
}

.cfs-input-wraper {
  position: relative;
}

.cfs-input-wraper.cfs-inputwrap-otp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cfs-btn-secondary-outline.cfs-btn-outline {
  margin-top: 0rem;
  padding: 12px 18px;
  border-style: solid;
  border-width: 1.2px;
  border-color: #dbdbdb;
  border-radius: 5px;
  background-color: transparent;
  box-shadow: none;
  -webkit-perspective: 66px;
  perspective: 66px;
  -webkit-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
  -webkit-transform: perspective(0px);
  transform: perspective(0px);
  -webkit-transition: background-color 150ms cubic-bezier(.55, .055, .675, .19);
  transition: background-color 150ms cubic-bezier(.55, .055, .675, .19);
  color: #454545;
  font-size: 0.9rem;
  line-height: 0.9rem;
  font-weight: 600;
  letter-spacing: -0.2px;
}

.cfs-btn-secondary-outline.cfs-btn-outline:hover {
  border-color: #d8d8d8;
  border-radius: 5px;
  background-color: #fafafa;
}

.ccco-mc-form {
  width: 100%;
  margin-top: 1rem;
}

.cfs-form-inner {
  display: block;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cfs-linkblock {
  color: #000;
  text-decoration: none;
}

.cfs-linkblock:hover {
  color: #166088;
}

.cfs-form-input-container {
  position: relative;
  width: 100%;
}

.cfs-form-input-container.cfs-form-input-container--otp {
  width: 100%;
}

.cfs-form-input {
  height: 40px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #166088;
  border-radius: 3px;
  font-size: 0.8rem;
  line-height: 1.1rem;
}

.cfs-form-input.cfs-form-input-light {
  width: 100%;
  border-color: #dedede;
}

.cfs-form-input.cfs-form-input-light.cfs-form-input-rcb-countrycode {
  position: absolute;
  left: 0px;
  top: auto;
  width: 80px;
  height: 45px;
  margin-bottom: 0px;
  border-width: 1.5px;
}

.cfs-form-input.cfs-form-input-light.cfs-form-input-otp {
  width: 24%;
  height: 70px;
  border-bottom: 3px solid #166088;
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
}

.cfs-form-input.cfs-form-input-light.cfs-form-input-rcb {
  height: 45px;
  border-width: 1.2px;
  border-color: #166088;
  background-color: #fff;
}

.cfs-form-input.cfs-form-input-light.cfs-form-input-rcb::-webkit-input-placeholder {
  color: #166088;
}

.cfs-form-input.cfs-form-input-light.cfs-form-input-rcb:-ms-input-placeholder {
  color: #166088;
}

.cfs-form-input.cfs-form-input-light.cfs-form-input-rcb::-ms-input-placeholder {
  color: #166088;
}

.cfs-form-input.cfs-form-input-light.cfs-form-input-rcb::placeholder {
  color: #166088;
}

.cfs-form-input.cfs-form-input-light.cfs-form-input-rcb.cfs-field-contact {
  position: relative;
}

.cfs-btn-primary.cfs-btn-orange {
  margin-top: 0rem;
  padding: 12px 18px;
  border-style: solid;
  border-width: 1px;
  border-color: #db8313;
  background-color: #f08122;
  box-shadow: none;
  -webkit-perspective: 66px;
  perspective: 66px;
  -webkit-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
  -webkit-transform: perspective(0px);
  transform: perspective(0px);
  -webkit-transition: background-color 150ms cubic-bezier(.55, .055, .675, .19);
  transition: background-color 150ms cubic-bezier(.55, .055, .675, .19);
  font-size: 0.9rem;
  line-height: 0.9rem;
  font-weight: 600;
  letter-spacing: -0.2px;
}

.cfs-btn-primary.cfs-btn-orange:hover {
  border-color: #ff8f00;
  background-color: #ff7f11;
}

.cfs-mobile-locations {
  display: none;
}

.ccco-checkout-mobile {
  display: none;
}

@media screen and (max-width: 991px) {
  .cc-checkout-container {
    width: 92vw;
  }
  .cc-checkout-container.ccco-summary-container {
    width: 90vw;
  }
  .cc-vsmall-text {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .tooltip-icon-2 {
    font-size: 1.1rem;
    line-height: 1.1rem;
  }
  .astats-upgrade {
    width: 100%;
  }
  .cc-desc {
    font-size: 0.7rem;
    line-height: 1.2rem;
  }
  .mb-nav-icon {
    color: rgba(255, 255, 255, 0.5);
    font-size: 2.2rem;
    line-height: 2.2rem;
  }
  .mb-nav-desc {
    color: #fff;
    font-size: 0.8rem;
    line-height: 0.9rem;
  }
  .cfs-mobile-bottom-fixed.mobile-fixednavigation {
    z-index: 100;
    display: none;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    background-color: #166088;
  }
  .cfs-mob-cd-close {
    font-family: 'La solid 900', sans-serif;
    color: #166088;
    text-decoration: none;
  }
  .cfs-btn-secondary.cfs-btn-outline {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cfs-btn-secondary-outline.cfs-btn-outline {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-otp {
    width: 70px;
    height: 20px;
  }
  .cfs-mobile-locations {
    display: none;
  }
  .ccco-checkout-mobile {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .cc-form-field {
    color: #333;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .cc-primary--cta.ccco-mt20.ccoo-cta-retry.ccco-mc-nextcta {
    position: relative;
    width: 100%;
    background-color: #3f54ff;
    font-size: 0.9rem;
  }
  .cc-primary--cta.ccco-mt20.ccco-pays-invoicebtn.ccco-cm-invoice-btn {
    position: relative;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 10px;
    margin-left: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-style: solid;
    border-width: 1px;
    border-color: #3f54ff;
    background-color: #fff;
    color: #3f54ff;
    font-size: 0.9rem;
  }
  .cc-primary--cta.ccco-mt20.ccco-pays-invoicebtn.ccco-cm-addnewcard-btn {
    position: relative;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-style: solid;
    border-width: 1px;
    border-color: #3f54ff;
    background-color: #fff;
    color: #3f54ff;
    font-size: 0.9rem;
  }
  .cc-primary--cta.ccco-mt20.ccco-mc-nextcta {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .cc-co-form-field {
    color: #333;
    font-size: 0.8rem;
    font-weight: 600;
  }
  .cc-co-form-field.cc-co-form-textarea {
    height: 80px;
  }
  .cc-co-form-label.ccco-saved-sep.ccco-cm-saved-sep {
    margin-right: auto;
    margin-left: auto;
  }
  .cc-forminput-25.ccco-cm-form50 {
    width: 48%;
  }
  .cc-co-summary-pwrap.ccco-cm-summary-pwrap {
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
  }
  .ccco-sugesstionbox.ccco-cm-sugestion-box {
    margin-top: 20px;
  }
  .ccco-sucards-wrap.ccco-sucards-wrap-cm {
    overflow: visible;
  }
  .ccco-su-pcard--img.ccco-su-pcard--img-cm {
    height: 70px;
  }
  .ccco-su-pcard-desc--left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .ccco-su-pcard-desc--left.ccco-fv.ccco-w-auto.ccco-cm-credit-wrap {
    width: 100%;
    margin-bottom: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: 1px none #cdcdcd;
    border-bottom: 1px none #cdcdcd;
  }
  .cc-pright-bd--inner.ccco-cm-100 {
    width: 100%;
  }
  .ccco-sum-cartwrap.ccco-cm-cartwrap {
    width: 103%;
    max-height: 25vh;
    margin-bottom: 20px;
    padding-top: 30px;
    padding-right: 3%;
    padding-bottom: 0px;
  }
  .ccco-sum-cartwrap.ccco-cm-cartwrap.ccco-cm-cartwrap-bundle {
    max-height: 50vh;
    padding-right: 20px;
  }
  .ccco-saved-wrap.ccco-mb0.ccco-cm-saved {
    width: 100%;
    margin-bottom: 0px;
  }
  .ccco-process-title.ccco-process-title-cm {
    position: relative;
    font-size: 1.1rem;
    line-height: 1.9rem;
  }
  .ccco-process-inner-contianer.ccco-process-inner-contianer-cm {
    max-width: 100%;
  }
  .ccco-process-rlink.ccco-process-rlink-cm {
    margin-right: auto;
    margin-left: auto;
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
  .ccco-pays-details-wrap.ccco-cm-paydetails {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .ccco-su-pcard-price-fv.ccco-su-pcard-price-fv-cm {
    width: 100%;
    margin-top: 5px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .ccco-su-pcard--add-sp.ccco-cm-sp-added {
    background-color: #21c226;
  }
  .ccco-su-pcard--add-sp.ccco-cm-spacard-add {
    border-style: solid;
    border-width: 1px;
    border-color: #3f54ff;
    background-color: #fff;
    color: #3f54ff;
  }
  .ccco-su-pcard--add-sp.ccco-cm-spacard-add.ccco-cm-spacard-addded {
    border-style: solid;
    border-color: #30b62c;
    background-color: #3cc64a;
    color: #fff;
  }
  .mb-nav-icon {
    color: #fff;
    font-size: 2.1rem;
    line-height: 2.2rem;
  }
  .mb-nav-icon.mb-ico-ls {
    color: #fff;
  }
  .mb-nav-desc {
    font-size: 0.7rem;
    line-height: 0.9rem;
  }
  .cfs-mobile-bottom-fixed {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #06164c;
  }
  .cfs-mobile-bottom-fixed.mobile-fixednavigation {
    z-index: 20000000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 80px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #166088;
  }
  .mn-nav-wrap {
    padding: 20px;
  }
  .mn-nav-wrap.mn-nav-appoint {
    background-color: #f08122;
  }
  .cfs-mob-c-title {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cfs-mob-cd-close {
    padding: 8px 0px 8px 10px;
    font-family: 'La solid 900', sans-serif;
    color: #166088;
    font-size: 1.1rem;
    font-weight: 400;
    text-decoration: none;
  }
  .cfs-mob-cd-buttonloc {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 6px 6px 6px 7px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1.2px;
    border-color: #166088;
    border-radius: 50%;
    background-color: rgba(22, 96, 136, 0.12);
    opacity: 1;
    font-family: 'Open Sans', sans-serif;
    color: #fff;
    font-size: 2rem;
    line-height: 2rem;
    text-decoration: none;
  }
  .cfs-form-field-desc {
    margin-bottom: 20px;
    font-size: 0.7rem;
    font-style: italic;
  }
  .location-next.cfs-cta-location-card {
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-decoration: none;
  }
  .cfs-form-label.color-primary {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cfs-cloc-desc {
    position: relative;
    margin-top: 0px;
    margin-right: 10px;
    padding: 2px 6px;
    border-radius: 3px;
    background-color: #12355b;
    color: #fff;
    font-size: 0.8rem;
  }
  .cfs-cloc-details-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 80%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cfs-cloc-details-container.cfs-cloc-detail-full {
    width: 100%;
  }
  .cfs-btn-secondary.cfs-btn-outline.cfs-btn-locations {
    display: none;
  }
  .cfs-btn-secondary.cfs-btn-outline.cfs-cta-confirmappoint-cta {
    margin-top: 2rem;
  }
  .cfs-btn-secondary.cfs-btn-outline.cfs-cta-confirmappoint-cta.cfs-w-auto {
    width: auto;
  }
  .cfs-input-wraper {
    width: 100%;
  }
  .cfs-input-wraper.cfs-inputwrap-otp {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cfs-btn-secondary-outline.cfs-btn-outline {
    width: 100%;
    margin-top: 2rem;
    border-color: #166088;
    color: #166088;
  }
  .ccco-mc-form {
    position: relative;
    margin-top: 0rem;
  }
  .cfs-cta-confirm-appoint-ico {
    padding: 8px 10px 10px;
    border-radius: 50%;
    background-color: #38b838;
    font-family: 'La solid 900', sans-serif;
    color: #fff;
    font-size: 4rem;
    line-height: 4rem;
  }
  .cfs-cta-location-tab {
    display: none;
    overflow: auto;
    width: 100%;
    height: 60vh;
    max-height: 60vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .cfs-cta-location-tab.ccco-cm-register {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .cfs-cta-location-tab.cfs-loc-tab-level-2 {
    display: none;
  }
  .cfs-cta-location-tab.cfs-loc-tab-level-singlehospital {
    display: none;
  }
  .cfs-cta-location-tab.cfs-loc-tab-level-4 {
    display: none;
  }
  .cfs-cta-location-tab.cfs-loc-tab-level-appoint-otp {
    display: none;
  }
  .cfs-cta-location-tab.cfs-loc-tab-level-appoint {
    display: none;
  }
  .cfs-cta-location-tab.cfs-loc-tab-level-3 {
    display: none;
  }
  .cfs-form-inner.ccco-cm-form-inner {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .cfs-cloc-dwrap-details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cfs-mob-cd-btn--ico {
    font-family: 'La solid 900', sans-serif;
    color: #166088;
  }
  .cfs-cloc-formwrap {
    margin-top: 30px;
  }
  .cfs-mob-locations-inner {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cfs-cloc-single-wrap.location-next {
    color: #000;
    text-decoration: none;
  }
  .cfs-cta-confirmappoint-title {
    margin-top: 20px;
    font-size: 1.1rem;
    line-height: 1.7rem;
    font-weight: 500;
    letter-spacing: 0px;
  }
  .cfs-cta-location-details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .cfs-mob-cdrawer-title {
    font-size: 0.85rem;
    font-weight: 600;
    letter-spacing: -0.2px;
  }
  .cfs-mob-cdrawer-title.ccco-cm-fixed-bigamount {
    position: relative;
    font-size: 1.6rem;
    line-height: 1.7rem;
    font-weight: 700;
    letter-spacing: -0.2px;
  }
  .cfs-mob-cdrawer-title.ccco-cm-fixed-bigamount.ccco-cm-fixed-bigamount-cancel {
    padding-right: 10px;
    color: #757575;
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 400;
    text-decoration: line-through;
  }
  .cfs-cta-confirmappoint-desc {
    margin-top: 10px;
    text-align: center;
  }
  .cfs-clock-arrow {
    font-family: 'La solid 900', sans-serif;
    color: #166088;
    font-size: 1.2rem;
    font-weight: 400;
  }
  .cfs-form-input-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .cfs-form-input-container.cfs-form-input-container--otp.cfs-mb-20 {
    margin-bottom: 20px;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb-countrycode {
    top: 0px;
    height: 45px;
    border-width: 1.2px;
    border-color: #166088;
    border-right-style: none;
    border-radius: 0px;
    background-color: #fff;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb-countrycode::-webkit-input-placeholder {
    color: #166088;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb-countrycode:-ms-input-placeholder {
    color: #166088;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb-countrycode::-ms-input-placeholder {
    color: #166088;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb-countrycode::placeholder {
    color: #166088;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-otp {
    width: 20%;
    height: 70px;
    border-style: none none solid;
    border-width: 1.2px 1.2px 2px;
    border-color: #166088;
    border-radius: 0px;
    background-color: rgba(47, 123, 163, 0.05);
    color: #166088;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 700;
    text-align: center;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-otp::-webkit-input-placeholder {
    color: #cfcfcf;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 300;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-otp:-ms-input-placeholder {
    color: #cfcfcf;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 300;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-otp::-ms-input-placeholder {
    color: #cfcfcf;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 300;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-otp::placeholder {
    color: #cfcfcf;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 300;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb {
    height: 45px;
    margin-bottom: 5px;
    padding-left: 10px;
    border-width: 1.2px;
    border-color: #166088;
    background-color: #fff;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb::-webkit-input-placeholder {
    color: #166088;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb:-ms-input-placeholder {
    color: #166088;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb::-ms-input-placeholder {
    color: #166088;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb::placeholder {
    color: #166088;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb.cfs-field-contact {
    padding-left: 90px;
  }
  .location-prev {
    margin-right: 20px;
    font-family: 'La solid 900', sans-serif;
    color: #166088;
    font-size: 1.3rem;
    text-decoration: none;
  }
  .location-prev.ccco-mc-prevcta {
    color: #868686;
    font-weight: 400;
  }
  .cfs-cloc-cta-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cfs-btn-primary.cfs-btn-orange.cfs-btn-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .cfs-btn-primary.cfs-btn-orange.cfs-btn-center.location-next {
    width: 100%;
  }
  .cfs-cloc-level-title {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fafafa;
  }
  .cfs-cloc-hospitaldetail {
    width: 100%;
    margin-top: 20px;
    padding: 20px 0px;
  }
  .cfs-cloc-hospitaldetail.cfs-cta-confirm {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cfs--cd-locations-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .cfs-cloc-dwrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cfs-mob-c-desc-sm {
    margin-top: 5px;
    font-size: 0.9rem;
  }
  .cfs-mobile-locations {
    position: fixed;
    bottom: 0px;
    z-index: 900000000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: auto;
    padding: 30px 30px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fafafa;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    -webkit-transition: -webkit-transform 400ms ease;
    transition: -webkit-transform 400ms ease;
    transition: transform 400ms ease;
    transition: transform 400ms ease, -webkit-transform 400ms ease;
  }
  .cfs-cta-location-image {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 60px;
    height: 60px;
    margin-right: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffe9d7;
  }
  .cfs-cloc-title {
    color: #000;
    font-size: 1.1rem;
    line-height: 1.4rem;
    font-weight: 600;
  }
  .ccco-checkout-mobile {
    position: fixed;
    bottom: 0px;
    z-index: 900000000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    width: 100%;
    height: auto;
    max-height: 95vh;
    padding: 30px 30px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    -webkit-transition: -webkit-transform 400ms ease;
    transition: -webkit-transform 400ms ease;
    transition: transform 400ms ease;
    transition: transform 400ms ease, -webkit-transform 400ms ease;
  }
  .ccco-cm-inner {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .ccco-checkout-mob-tab {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    width: 100%;
    height: auto;
    max-height: 60vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .ccco-checkout-mob-tab.ccco-cm-register {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .ccco-checkout-mob-tab.cfs-loc-tab-level-2 {
    display: none;
  }
  .ccco-checkout-mob-tab.cfs-loc-tab-level-singlehospital {
    display: none;
  }
  .ccco-checkout-mob-tab.cfs-loc-tab-level-4 {
    display: none;
  }
  .ccco-checkout-mob-tab.cfs-loc-tab-level-appoint-otp {
    display: none;
  }
  .ccco-checkout-mob-tab.cfs-loc-tab-level-appoint {
    display: none;
  }
  .ccco-checkout-mob-tab.cfs-loc-tab-level-3 {
    display: none;
  }
  .ccco-checkout-mob-tab.ccco-cm-guest-form {
    display: none;
  }
  .ccco-checkout-mob-tab.ccco-cm-cardselect {
    position: relative;
    display: none;
    width: 103%;
    padding-right: 3%;
  }
  .ccco-checkout-mob-tab.ccco-cm-addcard {
    position: relative;
    display: none;
  }
  .ccco-checkout-mob-tab.ccco-cm-addcardsave {
    display: none;
  }
  .ccco-checkout-mob-tab.ccco-cm-prosummary {
    display: none;
    max-height: none;
  }
  .ccco-checkout-mob-tab.ccco-cm-prosummary-sugessted {
    display: none;
    max-height: 70vh;
  }
  .ccco-checkout-mob-tab.ccco-cm-sucess-tab {
    display: none;
    width: 103%;
    padding-right: 3%;
  }
  .ccco-checkout-mob-tab.ccco-cm-processing-tab {
    display: none;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .ccco-checkout-mob-tab.ccco-cm-failed-tab {
    display: none;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .ccco-checkout-mob-tab.ccco-cm-prosummary-bundle {
    display: none;
    max-height: none;
  }
  .ccco-checkout-ctawrap {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .ccco-checkout-ctawrap.ccco-cm-ctawrap-mobwrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .ccco-checkomob-section-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 5px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #cdcdcd;
  }
  .ccco-cm-stitle {
    position: relative;
    font-size: 0.8rem;
    line-height: 1.2rem;
    font-weight: 400;
  }
  .ccco-cm-cardslect {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .ccco-cm-cardselect-box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 48%;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 3px;
    -webkit-transition: background-color 200ms ease;
    transition: background-color 200ms ease;
    color: #333;
    text-decoration: none;
  }
  .ccco-cm-cardselect-box:hover {
    background-color: #f5f5f5;
  }
  .ccco-cm-cs-img {
    width: 60px;
    margin-bottom: 0px;
  }
  .ccco-cm-cs-img.ccco-cm-cs-paypal {
    width: 80px;
    height: auto;
  }
  .ccco-cm-cardselect-img-wrpa {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 50px;
    margin-bottom: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .ccco-cm-sp-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .ccco-dh-title-fh {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .ccco-checkout-mob-credit {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding-top: 5px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .ccco-cm-fv {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .ccco-cm-summary-botton {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 8px 10px 10px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px none #cdcdcd;
    background-color: #f3f3f3;
    box-shadow: none;
  }
  .ccco-cm-ps-fixed {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .ccco-cm-fixed-coupon {
    position: relative;
    margin-top: 5px;
    padding-bottom: 2px;
    border-bottom: 1px dashed #3f54ff;
    color: #3f54ff;
    font-size: 0.7rem;
    line-height: 1rem;
    font-weight: 400;
    text-decoration: none;
  }
  .ccco-cm-ps-fh {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 5px;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .ccco-cm-sp-added-ico {
    position: relative;
    margin-right: 5px;
    font-family: 'La solid 900', sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
  }
}

@media screen and (max-width: 479px) {
  .cc-co-title-wrap.ccco-title-success.ccco-cm-psucces-title {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .cc-primary--cta {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 0.7rem;
  }
  .cc-primary--cta.ccco-mt20.ccoo-cta-retry.ccco-mc-nextcta {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .cc-co-total-amount.ccco-color-black.cc-co-total-amount-cm {
    padding-left: 10px;
    font-size: 1rem;
  }
  .cc-vsmall-text {
    font-size: 0.9rem;
  }
  .cc-co-summary-pwrap.ccco-cm-summary-pwrap {
    height: 100%;
    min-height: 70px;
    padding-top: 0px;
    padding-bottom: 20px;
  }
  .ccco-su-pcard--img.ccco-su-pcard--img-cm {
    height: 60px;
  }
  .cc-co-outline-btn {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 0.7rem;
  }
  .ccco-sum-cartwrap.ccco-cm-cartwrap {
    overflow: auto;
    height: 100%;
    padding-top: 20px;
  }
  .ccco-process-title.ccco-process-stitle.ccco-process-stitle-cm {
    font-size: 1rem;
    line-height: 1.9rem;
  }
  .ccco-process-title.ccco-process-title-cm {
    font-size: 1rem;
    line-height: 1.7rem;
  }
  .ccco-process-rlink {
    max-width: 80%;
  }
  .ccco-process-rlink.ccco-process-rlink-cm {
    line-height: 24px;
  }
  .ccco-progress-pendingico.ccco-progress-pendingico-cm {
    width: 80px;
    height: 80px;
  }
  .ccco-paysuccess-ico.ccco-paysuccess-ico-cm {
    font-size: 3.8rem;
    line-height: 4rem;
  }
  .ccco-pays-titlewrap.ccco-pays-titlewrap-cm {
    width: 100%;
    margin-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .ccco-title-fv.ccco-title-fv-cm {
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .ccco-pays-orderidwrap.ccco-pays-orderidwrap-cm {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .mb-nav-icon {
    color: #fff;
    font-size: 2rem;
    line-height: 2.2rem;
  }
  .mb-nav-icon.mb-ico-ls {
    font-size: 2rem;
    line-height: 2.2rem;
  }
  .mb-nav-desc {
    color: #fff;
    font-size: 0.7rem;
    line-height: 0.7rem;
    font-weight: 600;
    text-align: center;
  }
  .cfs-mobile-bottom-fixed.mobile-fixednavigation {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    max-height: 80px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #166088;
  }
  .mn-nav-wrap {
    width: 25%;
    padding: 12px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .mn-nav-wrap.mn-nav-appoint {
    padding-right: 12px;
    padding-left: 12px;
  }
  .cfs-mob-c-title {
    margin-bottom: 0px;
  }
  .cfs-mob-cd-close {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'La solid 900', sans-serif;
    color: #166088;
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-decoration: none;
  }
  .cfs-mob-cd-buttonloc {
    width: 45px;
    height: 45px;
    padding: 0px;
    background-color: #166088;
  }
  .cfs-cloc-desc {
    font-size: 0.7rem;
  }
  .cfs-cloc-details-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .cfs-cloc-details-container.cfs-cloc-detail-full {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cfs-btn-secondary.cfs-btn-outline {
    width: auto;
    font-size: 0.8rem;
  }
  .cfs-btn-secondary.cfs-btn-outline.cfs-btn-locations {
    width: 100%;
    margin-bottom: 20px;
  }
  .cfs-btn-secondary.cfs-btn-outline.cfs-cta-confirmappoint-cta.cfs-w-auto {
    margin-top: 1rem;
  }
  .cfs-input-wraper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cfs-input-wraper.cfs-inputwrap-otp {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .cfs-btn-secondary-outline.cfs-btn-outline {
    width: 100%;
    font-size: 0.8rem;
  }
  .cfs-btn-secondary-outline.cfs-btn-outline.m-0 {
    margin-top: 1rem;
  }
  .ccco-mc-form {
    margin-top: 0rem;
  }
  .cfs-cta-location-tab {
    display: none;
    overflow: auto;
    height: 100%;
    max-height: 50vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -o-object-fit: fill;
    object-fit: fill;
  }
  .cfs-cta-location-tab.ccco-cm-register {
    display: none;
  }
  .cfs-cta-location-tab.cfs-loc-tab-level-2 {
    position: relative;
  }
  .cfs-cta-location-tab.cfs-loc-tab-level-singlehospital {
    position: relative;
    display: none;
  }
  .cfs-cta-location-tab.cfs-loc-tab-level-4 {
    position: relative;
    display: none;
  }
  .cfs-cta-location-tab.cfs-loc-tab-level-appoint-otp {
    position: relative;
    display: none;
  }
  .cfs-cta-location-tab.cfs-loc-tab-level-appoint {
    position: relative;
    display: none;
  }
  .cfs-cta-location-tab.cfs-loc-tab-level-3 {
    position: relative;
    display: none;
  }
  .cfs-form-inner {
    position: relative;
    width: 100%;
  }
  .cfs-form-inner.ccco-cm-form-inner {
    height: 100%;
  }
  .cfs-cloc-dwrap-details {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .cfs-cloc-dwrap-details.mb-10 {
    margin-bottom: 10px;
  }
  .cfs-mob-cd-btn--ico {
    color: #fff;
    font-size: 1.6rem;
    line-height: 1.8rem;
  }
  .cfs-cloc-formwrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .cfs-cloc-single-wrap {
    width: 100%;
    color: #000;
    text-decoration: none;
  }
  .cfs-cta-confirmappoint-title {
    font-size: 1.1rem;
    text-align: center;
  }
  .cfs-cta-location-details {
    margin-top: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .cfs-mob-cdrawer-title {
    font-size: 0.9rem;
  }
  .cfs-mob-cdrawer-title.cfs-xs-txt {
    font-weight: 400;
  }
  .cfs-linkblock.cfs-color-link {
    color: #166088;
  }
  .cfs-form-input-container {
    margin-bottom: 10px;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb-countrycode {
    height: 42px;
    margin-bottom: 0px;
    border-style: solid none solid solid;
    border-width: 1.2px;
    border-color: #166088;
    background-color: #fff;
    color: #166088;
    font-weight: 600;
    text-align: center;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb-countrycode::-webkit-input-placeholder {
    color: #166088;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb-countrycode:-ms-input-placeholder {
    color: #166088;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb-countrycode::-ms-input-placeholder {
    color: #166088;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb-countrycode::placeholder {
    color: #166088;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-otp {
    position: relative;
    display: block;
    width: 22%;
    height: auto;
    min-height: 65px;
    margin-right: 10px;
    margin-bottom: 0px;
    padding-top: 8px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    border-style: none none solid;
    border-width: 1.2px 1.2px 2px;
    border-color: #166088;
    background-color: rgba(47, 123, 163, 0.05);
    color: #166088;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 700;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-otp::-webkit-input-placeholder {
    color: #dbdbdb;
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-otp:-ms-input-placeholder {
    color: #dbdbdb;
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-otp::-ms-input-placeholder {
    color: #dbdbdb;
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-otp::placeholder {
    color: #dbdbdb;
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb {
    height: 42px;
    margin-bottom: 0px;
    border-style: solid;
    border-width: 1.2px;
    border-color: #166088;
    background-color: transparent;
    color: #166088;
    font-weight: 600;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb::-webkit-input-placeholder {
    color: #166088;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb:-ms-input-placeholder {
    color: #166088;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb::-ms-input-placeholder {
    color: #166088;
  }
  .cfs-form-input.cfs-form-input-light.cfs-form-input-rcb::placeholder {
    color: #166088;
  }
  .location-prev {
    margin-right: 10px;
  }
  .cfs-btn-primary.cfs-btn-orange {
    position: relative;
  }
  .cfs-btn-primary.cfs-btn-orange.cfs-btn-center {
    width: 100%;
    margin-top: 1rem;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .cfs-cloc-level-title {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fafafa;
  }
  .cfs-cloc-hospitaldetail {
    margin-top: 10px;
    padding: 10px 0px;
  }
  .cfs-cloc-hospitaldetail.cfs-cta-confirm {
    padding-top: 20px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .cfs--cd-locations-inner {
    margin-top: 20px;
  }
  .cfs-mob-c-desc-sm {
    font-size: 0.8rem;
  }
  .cfs-mobile-locations {
    z-index: 200000000000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 25px;
    padding-bottom: 0px;
    padding-left: 25px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transform: translate(0px, 100%);
    -ms-transform: translate(0px, 100%);
    transform: translate(0px, 100%);
    -webkit-transition: none 0ms ease;
    transition: none 0ms ease;
  }
  .cfs-cta-location-image {
    width: 50px;
    height: 50px;
    border: 1px none #000;
    background-color: #ffe6d1;
  }
  .cfs-cloc-title {
    font-size: 1rem;
  }
  .ccco-checkout-mobile {
    z-index: 200000000000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 25px;
    padding-bottom: 0px;
    padding-left: 25px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    -webkit-transition: -webkit-transform 400ms ease;
    transition: -webkit-transform 400ms ease;
    transition: transform 400ms ease;
    transition: transform 400ms ease, -webkit-transform 400ms ease;
  }
  .ccco-cm-inner {
    margin-top: 20px;
  }
  .ccco-checkout-mob-tab {
    display: none;
    overflow: auto;
    height: 100%;
    max-height: 50vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -o-object-fit: fill;
    object-fit: fill;
  }
  .ccco-checkout-mob-tab.ccco-cm-register {
    display: none;
  }
  .ccco-checkout-mob-tab.cfs-loc-tab-level-2 {
    position: relative;
  }
  .ccco-checkout-mob-tab.cfs-loc-tab-level-singlehospital {
    position: relative;
    display: none;
  }
  .ccco-checkout-mob-tab.cfs-loc-tab-level-4 {
    position: relative;
    display: none;
  }
  .ccco-checkout-mob-tab.cfs-loc-tab-level-appoint-otp {
    position: relative;
    display: none;
  }
  .ccco-checkout-mob-tab.cfs-loc-tab-level-appoint {
    position: relative;
    display: none;
  }
  .ccco-checkout-mob-tab.cfs-loc-tab-level-3 {
    position: relative;
    display: none;
  }
  .ccco-checkout-mob-tab.ccco-cm-cardselect {
    display: none;
  }
  .ccco-checkout-mob-tab.ccco-cm-prosummary {
    display: none;
  }
  .ccco-checkout-mob-tab.ccco-cm-sucess-tab {
    display: none;
  }
  .ccco-checkout-mob-tab.ccco-cm-processing-tab {
    display: none;
  }
  .ccco-checkout-mob-tab.ccco-cm-failed-tab {
    display: none;
  }
  .ccco-checkout-mob-tab.ccco-cm-prosummary-bundle {
    display: none;
  }
  .ccco-checkout-mob-credit {
    margin-bottom: 20px;
    padding-top: 5px;
  }
  .ccco-cm-summary-botton {
    border: 1px solid transparent;
  }
}

@font-face {
  font-family: 'La brands 400';
  src: url('../fonts/la-brands-400.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'La 400';
  src: url('../fonts/la-regular-400.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'La solid 900';
  src: url('../fonts/la-solid-900.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Fa solid 900';
  src: url('../fonts/fa-solid-900.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Fa brands 400';
  src: url('../fonts/fa-brands-400.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}