*:focus {
  /* -webkit-focus-ring-color = '#5B9DD9' */
  outline:0 !important;
}
.ccco-arrow-prev,
.ccco-arrow-next{
z-index:10;
opacity:0;
transition:opacity 0.5s ease;
}
.ccco-sucards-wrap:hover .ccco-arrow-next,
.ccco-sucards-wrap:hover .ccco-arrow-prev{
opacity:1;
}
.slick-disabled{
display:none !important;
}
.ccco-progress-circle {
display: flex;
text-align: center;
justify-content: center;
align-items: center;
height:auto;
}
.ccco-progress-ring {
position: absolute;
width: 150px;
height: 150px;
border-radius: 50%;
animation: ring 2s linear infinite;
}
@keyframes ring {
0% {
  transform: rotate(0deg);
  box-shadow: 1px 5px 2px #3f54ff;
}
50% {
  transform: rotate(180deg);
  box-shadow: 1px 5px 2px #0080FF;
}
100% {
  transform: rotate(0360deg);
  box-shadow: 1px 5px 2px #3f54ff;
}
}
.ccco-progress-ring::before {
position: absolute;
left: 0;
content: "";
top: 0;
height: 100%;
width: 100%;
border-radius: 50%;
box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
}
.ccco-progress-txt {
animation: text 3s ease-in-out infinite;
}
@keyframes text {
50% {
  color: black;
}
}
.ccco-progress-circle-success {
margin-bottom: 3.5em;
border: 1px solid rgba(0, 0, 0, 0.2);
border-left-color: #5cb85c;
animation: ring-success 2s linear infinite;
position: relative;
display: inline-block;
vertical-align: top;
border-radius: 50%;
width: 150px;
height: 150px;
}
.load-complete {
-webkit-animation: none;
animation: none;
border-color: #5cb85c;
transition: border 500ms ease-out;
}
.ccco-progress-ring-check {
display: none;
}
.ccco-progress-ring-check.check-draw:after {
animation-duration: 800ms;
animation-timing-function: ease;
animation-name: checkmark;
transform: scaleX(-1) rotate(135deg);
}
.ccco-progress-ring-check:after {
opacity: 1;
  height: 4em;
  width: 2em;
  transform-origin: left top;
  border-right: 3px solid #5cb85c;
  border-top: 3px solid #5cb85c;
  content: '';
  left: 2.60rem;
  top: 5.2em;
  position: absolute;
}
@keyframes loader-spin {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
}
@keyframes checkmark {
0% {
  height: 0;
  width: 0;
  opacity: 1;
}
20% {
  height: 0;
  width: 1.75em;
  opacity: 1;
}
40% {
  height: 4em;
  width: 2em;
  opacity: 1;
}
100% {
  height: 4em;
  width: 2em;
  opacity: 1;
}
}
@keyframes ring-success {
0% {
  transform: rotate(0deg);
  box-shadow: 1px 5px 2px #4dc247;
}
50% {
  transform: rotate(180deg);
  box-shadow: 1px 5px 2px #3e9b39;
}
100% {
  transform: rotate(0360deg);
  box-shadow: 1px 5px 2px #4dc247;
}
}
/* Product Detail Drawer */
.ccco-p-detail-container{
transform: translatex(100%);
transition: transform 0.3s ease-out;
}
.ccco-p-detail-container-open{
transform: translatex(0);
}
.opacity-40{
opacity: .4;
transition: opacity 0.3s ease-out;
filter:blur(2px);
}
.cc-btn-trigger:before {
  position: absolute;
  z-index: -1;
  height: 20px;
  width: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  content: '';
  background-color: #e5e9f2;
  border-radius: 50%;
  opacity: 0;
  transition: all .3s;
}
.cc-btn-trigger:hover:before, .cc-btn-trigger:focus:before, .cc-btn-trigger.active:not(.revarse):before {
  opacity: 1;
  height: 120%;
  width: 120%;
  z-index: auto;
}
/*Product Slider*/
.slick-slide {
  margin: 0 20px;
}
/* the parent */
.slick-list {
  margin: 0 -20px;
}
.ccco-inputcard-visa{
transform:translateY(-10%);
}
.ccco-inputcard-master{
transform:translateY(-90%);
}